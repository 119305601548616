.login-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 2em 2em;
}

.login-dialog {
    position: relative;
    display: flex;
    flex-direction: row;
    /*padding: 3em 2em;*/
    width: 800px;
    max-width: 800px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)) !important;
    color: white;
}

.social-controls,
.login-form {
    padding: 42px 35px;
}

.social-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
}

.login-logo {
    width: 90px;
    height: 35px;
    background-image: url(/white_logo.svg);
    margin-top: -10px;
    margin-bottom: 27px;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.login-form h2 {
    margin-bottom: 0 !important;
    text-align: center;
    font-style: normal;
    font-size: 17px;
    line-height: 20px;
    /*text-transform: capitalize;*/
    color: #FFFFFF;
}

.login-form form {
    margin-top: 31px !important;
}

.login-form .field i {
    color: #BDC8D3;
}

.login-form .ui.input input, .login-form .ui.input input:focus {
    background: rgba(255, 255, 255, 0.12) !important;
    border-radius: 3px !important;
    box-sizing: border-box;
    height: 41px !important;
    color: #BDC8D3 !important;
    font-size: 17px;
    padding-left: 38px !important;
    outline: none;
    border: none;
}

.login-form .login-button {
    width: 100%;
    height: 41px;
    max-width: 100% !important;
    background: #EE3D3F !important;
    border: 2px solid #EE3D3F;
    border-radius: 3px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
    /*opacity: 0.85;*/
}

/*.login-form .login-button:hover {*/
/*    background: #ff585a!important;*/
/*    !*opacity: 1;*!*/
/*}*/

.login-form .login-button:active {
    color: #EE3D3F!important;
    background: transparent !important;
}

.divider-controls {
    position: absolute;
    top: 0;
    left: 40%;
    width: 1px;
    height: 100%;
    /*background-color: #00072a;*/
    background: linear-gradient(0deg, #00072A, #00072A), #FFFFFF;
}

.divider-controls div {
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    left: 40%;
    margin-left: -17px;
    height: 34px;
    width: 34px;
    background-color: #121630;
    border: 1px solid #00072a;
    border-radius: 50%;
    text-transform: lowercase;
    color: white;
    font-size: 12px;
    font-weight: 100;
    padding-left: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
}

a:hover {
    color: white;
}

.btn {
    width: 100%;
    padding: 10px;
    border: 2px solid transparent;
    border-radius: 2px;
    box-shadow: 0px 5px 7px rgba(77, 89, 143, 0.3);
    margin: 0 0 14px 0;
    opacity: 0.85;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none; /* remove underline from anchors */
    cursor: pointer;
    color: white;
}

.btn:hover {
    opacity: 1;
}

.btn i {
    font-size: 15px;
    margin-right: 7px;
}

.fb {
    background: linear-gradient(80.9deg, #4978FF 0%, #6A61FF 100%);
}
.fb.active{
    outline: none!important;
    background: transparent!important;
    /*border-image-source: linear-gradient(to right, #4978FF, #6A61FF); !*border gradient*!*/
    /*border-image-slice: 1;*/
    color: #6A61FF!important;
    border: 2px solid #6A61FF;
}

.google {
    background: linear-gradient(80.9deg, #F8645D 0%, #FF3D6C 100%);
}
.google.active{
    outline: none!important;
    background: transparent!important;
    /*border-image-source: linear-gradient(to right, #F8645D, #FF3D6C); !*border gradient*!*/
    /*border-image-slice: 1;*/
    color: #FF3D6C!important;
    border: 2px solid #FF3D6C;
}

.popup-error {
    background: #fff !important;
    border: 1px solid #e0b4b4 !important;
    color: #9f3a38 !important;
    font-size: 13px !important;
    font-weight: bold !important;
}

.signed-in-error {
    display: flex;
    align-items: center;
    padding: .833em 1em;
    border-radius: .28571429rem;
    -webkit-box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15);
    box-shadow: 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15);
}

.warning-icon-signed-in-wrapper {
    width: 17px;
    height: 17px;
    margin-right: 7px;
}

.warning-icon-signed-in {
    width: 17px;
    height: 17px;
    background-image: url(/warning.png);
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 765px) {
    .login-dialog {
        flex-direction: column;
    }

    .divider-controls {
        top: 45%;
        left: 0;
        width: 100%;
        height: 1px;
    }

    .social-controls,
    .login-form {
        width: 100%;
    }

    .divider-controls div {
        left: 50%;
    }

    .btn {
        width: 200px;
    }
}

@media screen and (max-width: 510px) {
    .login-wrapper {
        align-items: flex-start;
        padding: 0;
    }

    .login-dialog {
        width: 100%;
    }

    .login-form {
        padding-left: 15px;
        padding-right: 15px;
    }
}
