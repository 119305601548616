.thank-you-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
}

.thank-you-header {
    font-style: normal;
    font-weight: normal;
    font-size: 54px;
    line-height: 58px;
    text-align: center;
    color: #FFFFFF;
}


.back-to-site {
    margin-top: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    cursor: pointer;
}

.verify-bg{
    background: url(/bg_main_shadow-min.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
}

.verify-wrapper {
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}

.verify-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 481px;
    height: auto;
    min-height: 250px;
    padding: 42px 32px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)) !important;
    border-radius: 0 !important;
}

.verify-close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
    background-image: url(/close_icon_light.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.success-sending-message{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-sending-text {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    margin-top: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}

.success-sending-desc {
    font-size: 16px;
    margin-top: 30px;
    text-align: center;
    color: #FFFFFF;
}

.icon-success{
    width: 60px;
    height: 60px;
    background-image: url(/success.png);
    background-size: contain    ;
    background-repeat: no-repeat;
}

.icon-warning{
    width: 60px;
    height: 60px;
    background-image: url(/warning.png);
    background-size: contain    ;
    background-repeat: no-repeat;
}

.verify-block{
    width: 100%;
}

.verify-block label {
    color: #ffffff;
    font-size: 16px;
}

.verify-block input{
    margin-top: 5px;
    /*height: 41px;*/
    padding: 10px 12px;
    display: block;
    font-size: 17px;
    outline: none;
    transition: all .4s;
    width: 100% !important;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgba(77, 89, 143, 0.3);
    border-radius: 2px;
    border: none;
}

.verify-block input::placeholder {
    font-family: Helvetica;
    font-size: 13px;
    color: #5F6266;
    margin-top: -20px;
}

.button-send {
    width: 100%;
    height: 41px;
    margin-right: 0!important;
    margin-top: 27px!important;
    box-shadow: 0px 5px 7px rgba(77, 89, 143, 0.3) !important;
    border-radius: 2px !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    color: #fff !important;
    cursor: pointer;
    background-color: #007bff !important;
    border-color: #007bff !important;
    border: none;
}

.verify-form-error {
    /*position: absolute;*/
    color: #de1f27;
    font-size: 14px;
    padding-top: 5px;
}

@media screen and (max-width: 510px) {
    .verify-wrapper {
        padding: 15px 15px;
    }

    .verify-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}
