.support-bg {
    background: url(/bg_main_shadow-min.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
}

.support-wrapper {
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.support-form {
    position: relative;
    width: 100%;
    max-width: 481px;
    height: auto;
    padding: 42px 32px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)) !important;
    border-radius: 0 !important;
}

.support-close-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 12px;
    height: 12px;
    background-image: url(/close_icon_light.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.mail-icon-block {
    position: fixed;
    bottom: 75px;
    right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    /*border-bottom: 1px solid transparent;*/
    z-index: 2;
    
}

.mail-icon-block:hover {
    color: #EE3D3F;
    /*border-bottom: 1px solid #EE3D3F;*/
}

.mail-icon-block:hover .mail-icon {
    background-image: url(/mail_hover.svg);
}

.mail-icon {
    width: 20px;
    height: 20px;
    background-image: url(/mail.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 7px;
    cursor: pointer;
}

.form-block > div {
    margin-bottom: 17px;
}

.form-block label {
    color: #ffffff;
    font-size: 16px;
}

.form-block input, .form-block textarea {
    margin-top: 5px;
    /*height: 41px;*/
    padding: 10px 12px;
    display: block;
    font-size: 17px;
    outline: none;
    transition: all .4s;
    width: 100% !important;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 5px 7px rgba(77, 89, 143, 0.3);
    border-radius: 2px;
    border: none;
}

.form-block textarea {
    resize: vertical;
}

.form-block input::placeholder, .form-block textarea::placeholder {
    font-family: Helvetica;
    font-size: 13px;
    color: #5F6266;
    margin-top: -20px;
}

.button-send {
    width: 100%;
    height: 41px;
    box-shadow: 0px 5px 7px rgba(77, 89, 143, 0.3) !important;
    border-radius: 2px !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    color: #fff !important;
    cursor: pointer;
    background-color: #007bff !important;
    border-color: #007bff !important;
    border: none;
}

.support-form-error {
    color: #de1f27;
    font-size: 14px;
    padding-top: 5px;
}

.support-sending-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.support-sending-text {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    margin-top: 30px;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
}

.support-sending-desc {
    font-size: 16px;
    margin-top: 30px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
}

.support-icon-success {
    width: 60px;
    height: 60px;
    background-image: url(/success.png);
    background-size: contain;
    background-repeat: no-repeat;
}

@media only screen 
and (max-device-width : 500px) {
    .support-wrapper {
        padding: 15px 15px;
    }

    .support-form {
        padding-left: 15px;
        padding-right: 15px;
    }
    
   
}


