.thank-you-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
}

.thank-you-header {
    font-style: normal;
    font-weight: normal;
    font-size: 54px;
    line-height: 58px;
    text-align: center;
    color: #FFFFFF;
}


.back-to-site {
    margin-top: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    cursor: pointer;
}