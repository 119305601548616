body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    /*background-image: url(/bg1.svg);*/
    /*background-position: center center;*/
    /*background-repeat: no-repeat;*/
    /*background-attachment: fixed;*/
    /*background-size: cover;*/

    /**
    background: url(/bg_main_shadow-min.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
	**/
	
	
	

    background-color: #464646;
}



code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
    height: 100%;
}

@font-face {
    font-family: Helvetica-Regular;
    src: url(/fonts/HelveticaRegular.ttf);
}

@font-face {
    font-family: Helvetica;
    src: url(/fonts/HelveticaThin.ttf);
}
