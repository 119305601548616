.home-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 10px;
}

.dialog-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-wrapper {
    width: 342px;
    height: 142px;
}

.home-logo {
    width: 100%;
    height: 100%;
    background-image: url(/logo_big.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

.home-header {
    margin-top: 40px;
    font-weight: 500;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

.home-description {
    margin-top: 45px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ADD9F9;
}

.home-buttons {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home-buttons > button {
    width: 216px;
    height: 50px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
}
.home-buttons > A >button {
    width: 216px;
    height: 50px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
    outline: none;
}

.home-buttons .vip {
    background: #EE3D3F;
    border: 2px solid #EE3D3F;
    box-sizing: border-box;
    margin-right: 20px;
}

/*.home-buttons .vip:hover {*/
/*    background: #ff585a !important;*/
/*}*/

.home-buttons .vip:active {
    border: 2px solid #EE3D3F;
    box-sizing: border-box;
    border-radius: 4px;
    background: transparent !important;
    color: #EE3D3F!important;
}

.home-buttons .general {
    border: 2px solid #FFFFFF;
    background-color: transparent;
}

/*.home-buttons .general:hover {*/
/*    color: black;*/
/*    background-color: #FFFFFF;*/
/*}*/

.home-buttons .general:active {
    color: #EE3D3F!important;
    background-color: #FFFFFF;
}



@media screen and (max-width: 470px) {
    .home-content{
        height: auto;
    }

    .home-buttons {
        width: auto;
        flex-direction: column;
    }

    .home-buttons .vip {
        margin: 0 0 20px;
    }
}

@media screen and (max-width: 345px) {
    .logo-wrapper {
        width: 320px;
    }
}


.yellow{
    color:#ffe87c;
}
