.share-icon-block {
    position: fixed;
    bottom: 110px;
    right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
    /*border-bottom: 1px solid transparent;*/
    z-index: 2;
}

.share-icon-block:hover{
    color: #EE3D3F;
    /*border-bottom: 1px solid #EE3D3F;*/
}

.share-icon-block:hover .share-icon{
    background-image: url(/share_icon_hover.svg);
}

.share-icon {
    width: 20px;
    height: 20px;
    background-image: url(/share_icon.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 7px;
    cursor: pointer;
}
