.app-container{
    position: relative;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    width: 100%;
    height: 100%;
}

.app-container.blur-bg{
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
}

.content-container{
    width: 100%;
    flex: 1;
}

/*@media screen and (max-width: 510px) {
    .app-container{
        /*height: auto;
    }
}*/

.app-container::after { 
    background-color: #464646; 
    content: ""; 
    display: block; 
    position: absolute; 
    top: 0px; 
    left: 0px; 
    width: 100%; 
    height: 100%; 
    z-index: -1; 
    opacity: 1; 
    background: url(/bg_blur_web-min.png) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
}
