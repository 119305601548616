@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
@import url(http://fonts.googleapis.com/css?family=Roboto);
/* Custom Scrollbar */
::-webkit-scrollbar{width:0.5rem}
::-webkit-scrollbar-track{background:#ED2224; border-radius: 50px;}
::-webkit-scrollbar-thumb{background:#ED2224; border-radius: 50px;}


.p-15vh {padding-top: 7.5vh; padding-bottom: 7.5vh;}
.h-screen-90 {height: 85vh;}
.border-bg {background: #562459;}
.chat-bg {background: #16031E;}
.received-message-bg {background: #E5E5EA;}
.sent-message-bg {background: #0B93F6;}
.w-80 {width: 80%;}
.signout-btn {top:10px; right: 10px;}



#click{
  display: none;
}
label{
  position: absolute;
  right: 30px;
  bottom: 120px;
  height: 55px;
  width: 55px;
  background: #ED2224;
  text-align: center;
  line-height: 55px;
  border-radius: 50px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
label i{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s ease;
}
label i.fas{
  opacity: 0;
  pointer-events: none;
}
#click:checked ~ label i.fas{
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) rotate(180deg);
}
#click:checked ~ label i.fab{
  opacity: 0;
  pointer-events: none;
  transform: translate(-50%, -50%) rotate(180deg);
}

.wrapper{
  position: absolute;
  right: 80px;
  bottom: 150px;
  max-width: 400px;
  max-height:500px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 15px 20px rgba(0,0,0,0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.68,-0.55,0.265,1.55);
}
#click:checked ~ .wrapper{
  opacity: 1;
  bottom: 150px;
  pointer-events: auto;
}
.wrapper .head-text{
  line-height: 40px;
  color: #fff;
  border-radius: 15px 15px 0 0;
  padding: 0 20px;
  font-weight: 500;
  font-size: 20px;
  background: #ed2224;
}
.wrapper .chat-box{
  padding: 2px;
  width: 100%;
  max-height:500px;
}

.chat-box form{
  padding: 10px 5px;
  margin: 0px 0;
  border-radius: 0px;
  width: 100%;

}

.chat-box form .field:last-child{
  margin-bottom: 15px;
}
.chat-box form  input,
form .chat-box  textarea{
  width: 100%;
  height: 100%;
  padding-left: 10px;
  margin-left: 15px;
  /** border: 1px solid lightgrey;**/
  outline: none;
  border-radius: 25px;
  font-size: 16px;
  transition: all 0.3s ease;
  color:#ED2224
}
.chat-box input:focus,
form .textarea textarea:focus{
  border-color: #fc8383;
}
.chat-box form  input::placeholder,
form .textarea textarea::placeholder{
  color: #ed2224;
  transition: all 0.3s ease;
}
.chat-box form  input:focus::placeholder,
form .textarea textarea:focus::placeholder{
  color: #000;
}


.chat-box form  button{
  border: none;
  outline: none;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background: #ed2224;
  transition: all 0.3s ease;
  border-radius: 5px;
}
.chat-box form  button:active{
  transform: scale(0.97);
}


.chat-box .messages {
  padding: 0px;
  margin: 0;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-grow: 1;
  border-radius: 4px;
  background: transparent;
  max-height: 340px;
 
}
.chat-box .messages::-webkit-scrollbar {
  width: 5px;
}
.chat-box .messages::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgba(25, 147, 147, 0.1);
}
.chat-box .messages::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ed2224;
}
.chat-box .messages li {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 14px;
  margin: 0 0 20px 0;
  font: 12px/16px "Noto Sans", sans-serif;
  border-radius: 10px;
  background-color: rgba(25, 147, 147, 0.2);
  word-wrap: break-word;
  max-width: 81%;
}

