.header-wrapper {
    /*position: absolute;*/
    /*top: 0;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    min-height: 60px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%);
    z-index: 1;
    padding: 0 40px;
}

.header-logo-block{
    width: 97px;
    height: 38px;
    opacity: 1!important;
}

.header-logo{
    width: 100%;
    height: 100%;
    background-image: url(/livestream_planet_white.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.header-wrapper > div {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 14px;
    color: #FFFFFF;
    cursor: pointer;
}

.logout-wrapper {
    display: flex;
    align-items: center;
    opacity: 0.64;
    /*border-bottom: 1px solid transparent;*/
}

.logout-icon-block{
    width: 22px;
    height: 22px;
    margin-right: 3px;
}

.logout-wrapper:hover {
    color: #EE3D3F;
    opacity: 1;
}

/*.logout-wrapper:active {*/
/*    color: #EE3D3F;*/
/*    opacity: 1;*/
/*}*/

.logout-wrapper:hover .logout-icon {
    background-image: url(/logout_bold_hover.svg);
}

.logout-icon {
    width: 22px;
    height: 22px;
    background-image: url(/logout_bold.svg);
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width: 510px) {
    .header-wrapper{
        padding: 0 15px;
    }
}