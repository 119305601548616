.footer-wrapper{
    /*position: absolute;*/
    /*bottom: 0;*/
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 60px;
    min-height: 60px;
    padding: 0 40px;
    background: linear-gradient(0deg, #000320, #000320);
    z-index: 1;
}

.footer-wrapper div{
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 46px;
    text-align: center;
    /*text-transform: uppercase;*/
    color: #FFFFFF;
}

.footer-wrapper .powered-by{
    display: flex;
    align-items: center;
    margin-right: 6px;
}

.footer-wrapper .ar-solutions{
    width: 103px;
    height: 14px;
}

.footer-wrapper .ar-logo{
    width: 103px;
    height: 15px;
    background-image: url(/ar_solitions.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.footer-wrapper .sceenic{
    font-weight: bold;
    font-size: 12px;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
}

@media screen and (max-width: 510px) {
    .footer-wrapper {
        padding: 0 15px;
    }
}
