
html, body {margin: 0; height: 100%; overflow: hidden}
.stream-wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.additional-actions {
    position: fixed;
    bottom: 100px;
    right: 15px;
}
/*.additional-actions > div {*/
/*    margin-bottom: 10px;*/
/*}*/

.iframe-wrapper {
    width: 70%;
    height: 0;
    padding-bottom: calc(70% * 9 / 16);
    position: relative;
}

@media screen and (max-width: 510px) {
    .iframe-wrapper {
        width: 95%;
        padding-bottom: calc(95% * 9 / 16);
    }
}
