.payment-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-wrapper {
    width: 100%;
    max-width: 481px;
    height: auto;
    padding: 42px 32px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07));
    border-radius: 3px !important;
}

.payment-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.payment-header div:last-child {
    margin-top: 10px;
    font-weight: bold;
    font-size: 24px;
}

.App-payment {
    margin-top: 34px;
}

form {
    margin-top: 42px;
}

.form-actions {
    display: flex;
    justify-content: center;
}

.form-actions button {
    width: 100%;
    height: 41px;
    margin: 0 !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 15px !important;
    color: #FFFFFF !important;
    background: #EE3D3F;
    border: 2px solid #EE3D3F;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;

    /*outline: none!important;*/
}

/*.form-actions button:hover {*/
/*    background: #ff585a !important;*/
/*}*/

.form-actions button:active {
    color: #EE3D3F!important;
    background: transparent!important;
    outline: none!important;
}

.form-actions .loading {
    width: 41px;
    height: 41px;
    background-image: url(/loading.gif);
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.error-payment {
    display: flex;
    width: 100%;
    padding: 10px 15px;
    background-color: #f8d7da !important;
    border: 1px solid #c3404e !important;
    border-radius: .25rem !important;
    box-shadow: none !important;
    color: #721c24 !important;
    font-size: 13px !important;
    font-weight: bold !important;
    /*box-shadow: 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);*/
}

.error-payment .error-text {
    display: flex;
    align-items: center;
}

.warning-wrapper {
    width: 35px !important;
    display: flex;
    align-items: center;
}

.warning-icon {
    width: 25px;
    height: 25px;
    background-image: url(/warning.png);
    background-size: contain;
    background-repeat: no-repeat;
}

form > div {
    margin-top: 14px;
}

form .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

form .row div:first-child {
    flex: 3 1 !important;
}

form .row div:last-child {
    flex: 1;
    margin-left: 14px;
}

form input {
    width: 100%;
    height: 41px;
    padding: 10px 12px;
    display: block;
    font-size: 20px;
    outline: none;
    transition: all .4s;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12));
    border-radius: 3px !important;
    box-sizing: border-box;
    color: #BDC8D3 !important;
    border: none;
}

form input::placeholder {
    font-family: Helvetica;
    font-size: 13px;
    color: #BDC8D3;
    margin-top: -20px;
}

@media screen and (max-width: 510px) {
    .payment-content {
        padding: 0;
    }

    .payment-wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
}
